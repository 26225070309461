import React, { useEffect, useRef, useState } from 'react';
import styles from './MentionsVisualizer.module.scss';
import ClipCluster from './ClipCluster/ClipCluster';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import APIClips from 'src/API/APIClips';
import { MentionsVisualizerTypes } from './MentionsVisualizer.types';
import ClipFilters from './ClipFilters/ClipFilters';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { useTranslation } from 'react-i18next';
import { getChannels } from 'src/redux/actions/actions.channels';
import { dateToString } from '../common/helpers';
import { isMentionFindr } from 'src/Widgets/common/helpers';
import Popup from '../common/basicElements/Popup/Popup';
import SimpleSchedule from '../SearchAgents/SimpleSchedule/SimpleSchedule';
import config from 'src/config/config';
import useFetchClips from './hooks/useFetchClips';
import LoadingSpinner from '../common/basicElements/LoadingSpinner/LoadingSpinner';
import { getProfileGroups } from 'src/redux/actions/actions.profileGroups';
import TableContainer from '../ChannelGroups/containers/TableContainer/TableContainer';
import { Channel } from 'src/@types/shared.types';
import { setSelectedChannelIds } from 'src/redux/actions/actions.channelGroupsWidget';

const MentionsVisualizer = ({
    isRecent,
    isEdited,
    isSearch,
    tryQuery,
    tryQueryChannelGroup
}: MentionsVisualizerTypes.MentionsVisualizerProps) => {
    const profileGroups: MentionsVisualizerTypes.ProfileGroupNative[] = useAppSelector(
        (state) => state.profileGroups.data
    );
    const [hasSearched, setHasSearched] = useState(false);
    const [isFilterVisible, setFilterVisible] = useState(true);
    const [modalType, setModalType] = useState<string | undefined>(undefined);
    const [isSelectChannelsShown, setIsSelectChannelsShown] = useState(false);
    const [filteredData, setFilteredData] = useState<MentionsVisualizerTypes.Clip[]>([]);
    const [currentProfileGroup, setCurrentProfileGroup] = useState<
        MentionsVisualizerTypes.ProfileGroup | any
    >(profileGroups.find((group) => group.profiles.length > 0) || null);
    const [selectedDateRange, setSelectedDateRange] = useState<{
        startDate: Date;
        endDate: Date;
        customRange?: string;
    }>(
        isEdited
            ? {
                  startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
                  endDate: new Date()
              }
            : {
                  startDate: new Date(),
                  endDate: new Date()
              }
    );

    const [filter, setFilter] = useState<MentionsVisualizerTypes.Filter>({
        profileGroup: {
            value: {
                id: '',
                title: ''
            },
            label: '',
            profiles: []
        },
        profile: '',
        language: '',
        channelGroupTitle: '',
        selectedChannelGroup: undefined,
        editor: '',
        search: '',
        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
            customRange: 'Today'
        },
        mediaType: '',
        isDuplicateShown: false
    });

    //Toggle between channels and channel groups in search
    const [isToggleChannels, setIsToggleChannels] = useState(
        (filter.selectedChannelGroup || tryQueryChannelGroup) ? true : false
    );

    const [isDateRangeSet, setIsDateRangeSet] = useState(false);

    const isMobileScreen = useIsScreenSize(840);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const clustersContainerRef = useRef<HTMLDivElement>(null);

    const searchGroupExists = profileGroups.some(
        (group) => group.title === config.searchQueryGroupTitle
    );
    const searchProfileGroup = searchGroupExists
        ? profileGroups.find((group) => group.title === config.searchQueryGroupTitle)
        : null;

    const { data, loading, fetchClips, fetchEditedClips, fetchSearchQueryClips } =
        useFetchClips(
            selectedDateRange,
            searchGroupExists,
            searchProfileGroup,
            filter.selectedChannelGroup,
            isToggleChannels,
            tryQueryChannelGroup
        );

        
    const allProfileIds: any = (
        isSearch && searchProfileGroup ? [searchProfileGroup] : profileGroups
    )
        .filter((group) => group.profiles.length > 0)
        .map((group) => group.profiles.map((profile: { id: any }) => profile.id))
        .flat();

    const currentProfileIds = currentProfileGroup
        ? currentProfileGroup.profiles.map((profile: { id: any }) => profile.id)
        : allProfileIds;

    const handleApplyFilters = (filters: ((clip: any) => boolean)[]) => {
        isSearch && setHasSearched(true);
        const filteredData = data.filter((clip) =>
            filters.every((filter) => filter(clip))
        );
        setFilteredData(filteredData);
    };

    const fetchCountsStructureGenerator = async () => {
        const isEditedIds = currentProfileIds.map((id: any) => `${id}e`);
        const res =
            currentProfileIds.length > 0
                ? await APIClips.getCountsByProfile(
                      isEdited ? isEditedIds : currentProfileIds,
                      dateToString(selectedDateRange?.startDate),
                      dateToString(selectedDateRange?.endDate)
                  )
                : { data: [] };
        const profileHasCount = res.data.filter(
            (profile: { _count: number }) => profile._count > 0
        );

        const uniqueProfileHasCount = [
            ...new Set(
                profileHasCount.map((profile: { profileID: any }) => profile.profileID)
            )
        ] as never;
        // setProfilesHasCount(uniqueProfileHasCount);
        if (isEdited && !isSearch) {
            fetchEditedClips(uniqueProfileHasCount);
        } else if (isRecent && !isSearch) {
            fetchClips(uniqueProfileHasCount, selectedDateRange);
        }
    };

    const findProfileGroupByProfile = (profileId: number | string) => {
        const profileGroup = profileGroups.find(
            (group: { profiles: { id: number }[] }) =>
                group.profiles &&
                group.profiles.some((profile: { id: number }) => profile.id === profileId)
        );
        return isSearch ? searchProfileGroup : profileGroup;
    };

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        profileGroups.length === 0 && dispatch(getProfileGroups());
    }, []);

    useEffect(() => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            dateRange: {
                startDate: selectedDateRange.startDate,
                endDate: selectedDateRange.endDate,
                customRange: selectedDateRange.customRange
            }
        }));
    }, [selectedDateRange]);

    useEffect(() => {
        tryQueryChannelGroup && 
        setFilter((prevFilter) => {
            return {
               ...prevFilter,
                selectedChannelGroup: {
                    value: tryQueryChannelGroup.id,
                    label: tryQueryChannelGroup.title
                }
            };
        })
    }, []);

    useEffect(() => {
        setCurrentProfileGroup(
            profileGroups.find((group) => group.profiles.length > 0) || null
        );
        setFilter((prevFilter) => ({
            ...prevFilter,
            profileGroup: {
                value: {
                    id:
                        profileGroups.find((group) => group.profiles.length > 0)?.id ||
                        '',
                    title:
                        profileGroups.find((group) => group.profiles.length > 0)?.title ||
                        ''
                },
                label:
                    profileGroups.find((group) => group.profiles.length > 0)?.title || '',
                profiles:
                    profileGroups.find((group) => group.profiles.length > 0)?.profiles ||
                    []
            }
        }));
    }, [profileGroups]);

    useEffect(() => {
        dispatch(getChannels());
    }, [dispatch]);

    useEffect(() => {
        !isSearch && fetchCountsStructureGenerator();
    }, [selectedDateRange, currentProfileGroup]);

    const groupClipsByProfileGroup = (
        clips: MentionsVisualizerTypes.Clip[],
        profileGroups: any[]
    ) => {
        const profileGroupContent = profileGroups.map(
            (group: { id: number; title: any; profiles: any }) => {
                const clipsForGroup = clips.filter(
                    (clip: MentionsVisualizerTypes.Clip) => {
                        const profileId = clip.profileID;
                        const profileGroup = profileId
                            ? findProfileGroupByProfile(profileId)
                            : null;
                        return profileGroup && profileGroup.id === group.id;
                    }
                );
                const clipsToPass = clipsForGroup.map((clip) => {
                    const profileId = clip.profileID;
                    return profileId
                        ? {
                              ...clip,
                              profileId
                          }
                        : clip;
                });

                const sortedClips = clipsToPass.sort((a, b) => {
                    const dateA = new Date(a.hitTime);
                    const dateB = new Date(b.hitTime);
                    return dateB.getTime() - dateA.getTime();
                });

                return {
                    id: group.id,
                    title: group.title,
                    clips: sortedClips,
                    profiles: group.profiles
                };
            }
        );
        return profileGroupContent;
    };

    const profileGroupContent = groupClipsByProfileGroup(filteredData, profileGroups);

    const isMentionFindrApp = isMentionFindr();

    let modal;
    if (modalType) {
        switch (modalType) {
            case 'changeSchedule':
                modal = (
                    // @ts-ignore
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            setModalType(undefined);
                        }}
                        onCancel={() => {
                            setModalType(undefined);
                        }}
                    >
                        <SimpleSchedule />
                    </Popup>
                );
                break;
            case 'saveSearchAgent':
                modal = (
                    // @ts-ignore
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            setModalType(undefined);
                            //setIsSearchAgent(false);
                        }}
                        onCancel={() => {
                            setModalType(undefined);
                            //setIsSearchAgent(false);
                        }}
                    >
                        {t('want_to_add_search_agent')}
                    </Popup>
                );
                break;
            case 'deleteSearchAgent':
                modal = (
                    // @ts-ignore
                    <Popup
                        size="auto"
                        blockContent={true}
                        onOk={() => {
                            setModalType(undefined);
                            //setIsSearchAgent(false);
                        }}
                        onCancel={() => {
                            setModalType(undefined);
                            //setIsSearchAgent(false);
                        }}
                    >
                        {t('really_delete_search_agent')}
                    </Popup>
                );
                break;
        }
    }

    return (
        <div
            className={styles.mentionsVisualizerContainer}
            ref={clustersContainerRef}
            style={
                isFilterVisible && isSearch && !isMobileScreen
                    ? {
                          height: '100%',
                          overflow: 'hidden'
                      }
                    : isMentionFindrApp
                    ? { lineHeight: '1' }
                    : {}
            }
        >
            {modal}
            {
                <ClipFilters
                    data={data}
                    isToggleChannels={isToggleChannels}
                    setIsToggleChannels={setIsToggleChannels}
                    isEdited={isEdited}
                    isRecent={isRecent}
                    isSearch={isSearch}
                    isFilterVisible={isFilterVisible}
                    isSelectChannelsShown={isSelectChannelsShown}
                    setIsSelectChannelsShown={setIsSelectChannelsShown}
                    setFilterVisible={setFilterVisible}
                    setModalType={setModalType}
                    modalType={modalType}
                    fetchSearchClips={fetchSearchQueryClips}
                    defaultProfileGroup={currentProfileGroup}
                    tryQuery={tryQuery}
                    isDateRangeSet={isDateRangeSet}
                    setIsDateRangeSet={setIsDateRangeSet}
                    setFilter={setFilter}
                    filter={filter}
                    applyFilters={(filter: any) => {
                        const {
                            profileGroup,
                            profile,
                            language,
                            selectedChannelGroup,
                            editor,
                            mediaType,
                            isDuplicateShown,
                            dateRange
                        } = filter;

                        setCurrentProfileGroup(profileGroup);
                        setSelectedDateRange(dateRange);
                        handleApplyFilters([
                            (clip) =>
                                !profile || clip.profileTitle === profile.value.title,
                            (clip) =>
                                !profileGroup ||
                                clip.profileGroupTitle === profileGroup.value.title,
                            (clip) =>
                                !language || clip.language.slice(0, 2) === language.value,
                            (clip) =>
                                !selectedChannelGroup ||
                                clip.selectedChannelGroup === selectedChannelGroup,
                            (clip) => !editor || clip.editor === editor.value,
                            (clip) => !isDuplicateShown || clip.dupType === 'channel',
                            (clip) => !mediaType || clip.mediatype === mediaType.value
                        ]);
                    }}
                />
            }
            {isSelectChannelsShown ? (
                // @ts-ignore
                <TableContainer
                    onSelectionChange={(channelIds: Channel[]) => {
                        dispatch(setSelectedChannelIds(channelIds));
                    }}
                    isChannelGroupsWidget={false}
                />
            ) : loading ? (
                <div className={styles.loadingSpinnerContainer}>
                    <LoadingSpinner key={0} size="4rem" />
                </div>
            ) : data.length === 0 ? (
                <div className={styles.noDataMessage}>
                    {isEdited
                        ? t('No edited clips found')
                        : isRecent
                        ? t('No recent clips found')
                        : !isDateRangeSet
                        ? t('Please select a date range to start searching')
                        : isSearch
                        ? data.length === 0 && !hasSearched ? t('Type your query to start a search') : t('No search results found')
                        : t('Please write a query for search results')}
                </div>
            ) : (
                profileGroupContent.map(
                    (
                        profileGroupContent: MentionsVisualizerTypes.ProfileGroupContent,
                        index: number
                    ) => {
                        return (profileGroupContent.clips.length > 0 && isSearch) ||
                            profileGroupContent.clips.length > 0 ? (
                            <ClipCluster
                                profileGroupContent={profileGroupContent}
                                currentProfileGroup={currentProfileGroup}
                                key={index}
                                data={data}
                                filter={filter}
                                selectedDateRange={selectedDateRange}
                                isSearch={isSearch}
                                isEdited={isEdited}
                                isMentionFindrApp={isMentionFindrApp}
                            />
                        ) : null;
                    }
                )
            )}
        </div>
    );
};

export default MentionsVisualizer;
